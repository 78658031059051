/**
Color variable definitions
*/
/**
  Common variable definitions
*/
/**
  Font variable definitions
*/
/**
  Tables
*/
/**
  Links
*/
/**
  Code
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  color: #0000b3;
  text-decoration: underline;
}

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Aleo", serif, monospace;
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #f1bb1a;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: white;
  background-color: #333;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #333;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
  -webkit-appearance: textfield;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

mark {
  padding: 0.2em;
  background-color: white;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

main {
  display: block;
}

[hidden] {
  display: none;
}

header .header-top {
  background-color: #585944;
}

header .header-top .container ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

header .header-top .container ul li a {
  font-size: 0.875em;
  font-weight: 700;
  display: block;
  padding: 15px 15px 15px 0;
  color: white;
  text-decoration: none;
}

header .header-top .container ul li a i {
  display: inline-flex;
  vertical-align: initial;
  background-color: #f1bb1a;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

@media screen and (max-width: 1200px) {
  header .header-top .container ul li a i {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 1200px) {
  header .header-top .container ul li a {
    font-size: 0.7em;
  }
}

header .header-top .container ul li:last-child {
  margin-left: auto;
}

header .header-top .container ul li:last-child a {
  padding-right: 0;
}

header .header-top .container ul li:last-child a i {
  background-color: #93c854;
}

@media screen and (max-width: 767px) {
  header .header-top {
    display: none;
  }
}

header .header-bottom {
  background-color: white;
}

header .header-bottom .container .main-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
}

header .header-bottom .container .main-navbar ul {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

header .header-bottom .container .main-navbar ul li a {
  padding: 15px;
  color: #585944;
  font-weight: 700;
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  header .header-bottom .container .main-navbar ul li a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  header .header-bottom .container .main-navbar ul li a {
    font-size: 0.9rem;
  }
}

header .header-bottom .container .main-navbar ul li:last-child a {
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  header .header-bottom .container .main-navbar ul {
    overflow: hidden;
    width: 100%;
    height: auto;
    border-radius: 50%;
    background-color: white;
    transition: all 0.6s ease-in-out;
    transform-origin: right top;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 100px;
    z-index: -1;
    transform: scale(0);
    transform-origin: top right;
    padding-bottom: 15px;
  }
  header .header-bottom .container .main-navbar ul.open {
    border-radius: 0;
    transform: scale(1);
  }
}

header .header-bottom .container .main-navbar #btn-toggle-menu {
  display: none;
  border: 1px solid white;
  background-color: #585944;
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  padding: 0.35rem 0.7rem;
  z-index: 333;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  header .header-bottom .container .main-navbar {
    padding: 0px;
  }
  header .header-bottom .container .main-navbar #btn-toggle-menu {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  header .header-bottom .container {
    padding: 15px;
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) {
  header .header-bottom {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 89px;
  }
}

header .banner {
  width: 100%;
  position: relative;
  background: white url("../img/header-bg.png") center center no-repeat;
  background-size: cover;
}

header .banner .container {
  height: 100%;
  min-height: 70vh;
  display: flex;
  padding-bottom: 80px;
  padding-top: 100px;
  align-items: center;
  justify-content: flex-end;
}

header .banner .container .text-header {
  max-width: 50%;
  margin-top: 7%;
}

header .banner .container .text-header h1 {
  font-family: "Aleo", serif, monospace;
  color: #f1bb1a;
  font-weight: bold;
  font-size: 3.8rem;
  text-align: right;
}

@media screen and (max-width: 1024px) {
  header .banner .container .text-header h1 {
    font-size: 3rem;
  }
}

header .banner .container .text-header p {
  color: white;
  text-align: right;
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) {
  header .banner .container .text-header p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  header .banner .container .text-header {
    max-width: 100%;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 970px;
}

@media screen and (min-width: 1360px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    max-width: 1360px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 15px;
  }
}

body {
  font-family: "Poppins", sans-serif;
}

body #inicio,
body #obsequio,
body #testimonios,
body #productos {
  scroll-behavior: smooth;
}

body .custom-checkbox {
  padding-left: 25px;
  position: relative;
}

body .custom-checkbox input[type="checkbox"] {
  display: none;
}

body .custom-checkbox input[type="checkbox"]:checked + span {
  border-color: #f1bb1a;
}

body .custom-checkbox input[type="checkbox"]:checked + span:after {
  content: " ";
  width: 7px;
  height: 7px;
  display: block;
  top: 3px;
  left: 3px;
  position: absolute;
  background-color: #f1bb1a;
}

body .custom-checkbox input[type="checkbox"] + span {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 5px;
  border: 1px solid white;
}

footer {
  width: 100%;
  background: white url("../img/footer-bg.png") center center no-repeat;
  background-size: cover;
}

footer .container {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}

footer .container h3 {
  font-family: "Aleo", serif, monospace;
  font-weight: bold;
  font-size: 3rem;
  color: white;
}

@media screen and (max-width: 1200px) {
  footer .container h3 {
    font-size: 2rem;
  }
}

footer .container h5 {
  font-size: 1.6rem;
  color: white;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  footer .container h5 {
    font-size: 1.3rem;
  }
}

footer .container .btn-taste {
  margin: 50px 0;
  display: inline-block;
  padding: 15px 30px;
  color: white;
  font-weight: 600;
  border-radius: 30px;
  background: #93c854;
  text-decoration: none;
}

footer .container .btn-taste:after {
  content: "";
  clear: both;
  position: relative;
}

footer .container img.cards {
  display: block;
  max-width: 100%;
  margin: 0 auto 30px;
}

footer .container p {
  color: white;
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  footer .container p {
    font-size: 1rem;
  }
}

.call-action {
  background: white url("../img/call-action-bg.png") center center no-repeat;
  background-size: cover;
  z-index: 30;
  position: relative;
}

.call-action .cover {
  position: absolute;
  width: 70%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%);
}

@media screen and (max-width: 1200px) {
  .call-action .cover {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .call-action .cover {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
  }
}

@media screen and (max-width: 767px) {
  .call-action .cover {
    height: 60%;
  }
}

.call-action .container {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}

.call-action .container .form-container {
  width: 32%;
  background-color: #454637;
  padding: 50px 30px;
  margin-bottom: 10px;
  margin-top: -70px;
}

.call-action .container .form-container div h2 {
  margin-top: 0px;
  color: white;
  font-size: 1.7rem;
  font-family: "Aleo", serif, monospace;
  font-weight: bold;
  margin-bottom: 20px;
}

.call-action .container .form-container div p {
  font-size: 0.8rem;
  color: white;
  text-align: justify;
}

.call-action .container .form-container div p span {
  color: #f1bb1a;
}

.call-action .container .form-container div form {
  margin-top: 30px;
}

.call-action .container .form-container div form input[type="text"],
.call-action .container .form-container div form input[type="email"],
.call-action .container .form-container div form input[type="tel"] {
  border: 1px solid white;
  outline: none;
  box-shadow: none;
  background: transparent;
  color: white;
  font-weight: 700;
  padding: 8px 15px;
  margin-bottom: 15px;
  width: 100%;
}

.call-action .container .form-container div form input[type="text"]::-webkit-input-placeholder,
.call-action .container .form-container div form input[type="email"]::-webkit-input-placeholder,
.call-action .container .form-container div form input[type="tel"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.call-action .container .form-container div form input[type="text"]:-moz-placeholder,
.call-action .container .form-container div form input[type="email"]:-moz-placeholder,
.call-action .container .form-container div form input[type="tel"]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.call-action .container .form-container div form input[type="text"]::-moz-placeholder,
.call-action .container .form-container div form input[type="email"]::-moz-placeholder,
.call-action .container .form-container div form input[type="tel"]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.call-action .container .form-container div form input[type="text"]:-ms-input-placeholder,
.call-action .container .form-container div form input[type="email"]:-ms-input-placeholder,
.call-action .container .form-container div form input[type="tel"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.call-action .container .form-container div form label {
  color: white;
}

.call-action .container .form-container div form label span {
  color: #f1bb1a;
}

.call-action .container .form-container div form label input[type="checkbox"] {
  border-radius: 0;
  margin-right: 8px;
  vertical-align: middle;
}

.call-action .container .form-container div form button {
  width: 100%;
  padding: 10px 15px;
  color: black;
  background: #f1bb1a;
  box-shadow: none;
  outline: none;
  border: none;
  margin-top: 35px;
  font-weight: 600;
}

.call-action .container .form-container div form button:hover {
  background: #cc9c0c;
}

@media screen and (max-width: 1200px) {
  .call-action .container .form-container {
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .call-action .container .form-container {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .call-action .container .form-container {
    width: 100%;
  }
}

.call-action .container .info-container {
  padding: 90px 40px 0px;
  width: 35%;
  margin-top: -70px;
}

.call-action .container .info-container div h2 {
  font-family: "Aleo", serif, monospace;
  font-weight: 600;
  color: #585944;
  font-size: 4rem;
}

.call-action .container .info-container div h4 {
  font-size: 1.1rem;
  font-weight: 800;
  color: #93c854;
}

.call-action .container .info-container div p {
  margin-top: 30px;
  color: #585944;
  display: block;
  position: relative;
}

.call-action .container .info-container div p strong {
  display: block;
  width: 100%;
}

.call-action .container .info-container div a.btn-know-more {
  background: #93c854;
  color: white;
  border: none;
  padding: 12px 25px;
  font-weight: 600;
  border-radius: 24px;
  margin-top: 30px;
  text-decoration: none;
  display: inline-block;
}

.call-action .container .info-container div a.btn-know-more:hover {
  background: #7ab039;
}

@media screen and (max-width: 1200px) {
  .call-action .container .info-container {
    width: 45%;
  }
}

@media screen and (max-width: 1024px) {
  .call-action .container .info-container {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .call-action .container .info-container {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .call-action .container {
    flex-direction: column;
  }
}

.call-action img.astronauta {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 30%;
}

@media screen and (max-width: 1024px) {
  .call-action img.astronauta {
    max-width: 45%;
  }
}

.content {
  padding: 50px 0px;
  background: white;
}

.content .container .products .titles-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content .container .products .titles-container > div {
  width: 50%;
}

.content .container .products .titles-container > div strong {
  color: black;
  font-size: 1.3rem;
}

.content .container .products .titles-container > div h2 {
  font-size: 5rem;
  margin-top: 10px;
  font-family: "Aleo", serif, monospace;
  font-weight: 700;
  color: #93c854;
  width: auto;
}

@media screen and (max-width: 1200px) {
  .content .container .products .titles-container > div h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .products .titles-container > div h2 {
    font-size: 2rem;
  }
}

.content .container .products .titles-container > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.content .container .products .titles-container > div:last-child h2 {
  color: #f1bb1a;
  text-align: right;
  line-height: 1;
  display: inline-block;
}

.content .container .products .titles-container > div:last-child h2 small {
  font-size: 2rem;
  color: balck;
  line-height: 1;
  display: block;
  width: 100%;
  color: black;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .content .container .products .titles-container > div {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .content .container .products .titles-container {
    flex-direction: column;
  }
}

.content .container .products .products-container {
  display: flex;
  flex-direction: row;
}

.content .container .products .products-container > div {
  width: 35%;
}

.content .container .products .products-container > div:first-child {
  width: 65%;
}

@media screen and (max-width: 767px) {
  .content .container .products .products-container > div:first-child {
    width: 100%;
  }
}

.content .container .products .products-container > div .list-categories {
  list-style: none;
  padding-left: 0px;
  border-bottom: 2px solid #f8f8f8;
}

.content .container .products .products-container > div .list-categories li {
  position: relative;
  display: inline-block;
  padding: 10px 20px 10px 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #585944;
}

.content .container .products .products-container > div .list-categories li:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #93c854;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -7px;
}

@media screen and (max-width: 1200px) {
  .content .container .products .products-container > div .list-categories li:before {
    width: 12px;
    height: 12px;
    margin-top: -5px;
  }
}

@media screen and (max-width: 1200px) {
  .content .container .products .products-container > div .list-categories li {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .products .products-container > div .list-categories li {
    font-size: .6rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .products .products-container > div {
    width: 100%;
  }
}

.content .container .products .products-container .product-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4%;
}

.content .container .products .products-container .product-images .container-img {
  border-radius: 15px;
  overflow: hidden;
  background: #f8f8f8;
}

.content .container .products .products-container .product-images .container-img img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .content .container .products .products-container .product-images .container-img {
    margin-bottom: 15px;
  }
}

.content .container .products .products-container .cards-products .card {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
}

.content .container .products .products-container .cards-products .card .card-image {
  margin-right: 20px;
  width: 15%;
}

.content .container .products .products-container .cards-products .card .card-image img {
  width: 100%;
  height: auto;
}

.content .container .products .products-container .cards-products .card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.content .container .products .products-container .cards-products .card .card-content .title {
  margin: 0px 0px 8px 0px;
  color: #93c854;
  font-family: "Aleo", serif, monospace;
  font-weight: 600;
  font-size: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .content .container .products .products-container .cards-products .card .card-content .title {
    font-size: 1.1rem;
  }
}

.content .container .products .products-container .cards-products .card .card-content .content {
  padding: 0;
  color: #585944;
  font-size: 1.2rem;
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .content .container .products .products-container .cards-products .card .card-content .content {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .products .products-container {
    flex-direction: column;
  }
}

.content .container .testimonials {
  margin-top: 50px;
}

.content .container .testimonials .container h2 {
  font-size: 6rem;
  color: #93c854;
  text-align: center;
  font-family: "Aleo", serif, monospace;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .testimonials .container h2 {
    font-size: 2.5rem;
  }
}

.content .container .testimonials .container h4 {
  text-align: center;
  color: #585944;
  font-weight: 700;
  font-size: 2.1rem;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container h4 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .content .container .testimonials .container h4 {
    font-size: 1.2rem;
  }
}

.content .container .testimonials .container .carousel-container {
  margin-top: 100px;
}

.content .container .testimonials .container .carousel-container .item .card .info-client {
  display: flex;
  flex-direction: row;
  justify-content: middle;
}

.content .container .testimonials .container .carousel-container .item .card .info-client p {
  display: inline-block;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content .container .testimonials .container .carousel-container .item .card .info-client p strong,
.content .container .testimonials .container .carousel-container .item .card .info-client p span {
  display: block;
  color: #f1bb1a;
}

.content .container .testimonials .container .carousel-container .item .card .info-client p strong {
  font-size: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container .carousel-container .item .card .info-client p strong {
    font-size: 1.2rem;
  }
}

.content .container .testimonials .container .carousel-container .item .card .info-client p span {
  font-size: 1.2rem;
  margin-top: 15px;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container .carousel-container .item .card .info-client p span {
    font-size: 1rem;
    margin-top: 10px;
  }
}

.content .container .testimonials .container .carousel-container .item .card .info-client img {
  max-width: 110px;
  height: auto;
  display: inline-block;
  margin-right: 20px;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container .carousel-container .item .card .info-client img {
    max-width: 90px;
  }
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial {
  position: relative;
  margin-top: 40px;
  border: 10px solid #f1bb1a;
  padding: 30px;
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial .content {
  color: black;
  padding: 0;
}

@media screen and (max-width: 1200px) {
  .content .container .testimonials .container .carousel-container .item .card .content-testimonial .content {
    font-size: 0.9rem;
  }
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial .stars {
  list-style: none;
  padding: 0;
  margin: 0;
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial .stars li {
  display: inline-block;
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial .stars li .fa {
  color: #f1bb1a;
}

.content .container .testimonials .container .carousel-container .item .card .content-testimonial:before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: 20px solid #f1bb1a;
  border-right-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -45px;
  left: 100px;
}

.content .container .testimonials .container .carousel-container .owl-dots {
  margin: 30px 0;
  text-align: center;
}

.content .container .testimonials .container .carousel-container .owl-dots .owl-dot {
  outline: none;
  display: inline-block;
}

.content .container .testimonials .container .carousel-container .owl-dots .owl-dot span {
  display: block;
  width: 25px;
  height: 7px;
  background: rgba(88, 89, 68, 0.5);
  margin: 0px 5px;
}

.content .container .testimonials .container .carousel-container .owl-dots .owl-dot.active span {
  background: #93c854;
}
