.call-action {
  .cover {
    @media screen and (max-width: 1200px) {
      width: 85%;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    }

    @media screen and (max-width: 767px) {
      height: 60%;
    }

    position: absolute;
    width: 70%;
    height: 100%;
    bottom: 0px;
    left: 0px;
    background: rgba(white, 0.9);
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%);
  }

  .container {
    .form-container {
      div {
        h2 {
          margin-top: 0px;
          color: white;
          font-size: 1.7rem;
          font-family: $font-family-monospace;
          font-weight: bold;
          margin-bottom: 20px;
        }

        p {
          span {
            color: color(yellow);
          }

          font-size: 0.8rem;
          color: white;
          text-align: justify;
        }

        form {
          input[type="text"],
          input[type="email"],
          input[type="tel"] {
            @include placeholder {
              color: rgba(white, 0.8);
            }

            border: 1px solid white;
            outline: none;
            box-shadow: none;
            background: transparent;
            color: white;
            font-weight: 700;
            padding: 8px 15px;
            margin-bottom: 15px;
            width: 100%;
          }

          label {
            span {
              color: color(yellow);
            }

            input[type="checkbox"] {
              border-radius: 0;
              margin-right: 8px;
              vertical-align: middle;
            }

            color: white;
          }

          button {
            &:hover {
              background: darken(color(yellow), 10);
            }

            width: 100%;
            padding: 10px 15px;
            color: black;
            background: color(yellow);
            box-shadow: none;
            outline: none;
            border: none;
            margin-top: 35px;
            font-weight: 600;
          }

          margin-top: 30px;
        }
      }

      @media screen and (max-width: 1200px) {
        width: 40%;
      }

      @media screen and (max-width: 1024px) {
        width: 50%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      width: 32%;
      background-color: color(olive-dark);
      padding: 50px 30px;
      margin-bottom: 10px;
      margin-top: -70px;
    }

    .info-container {
      div {
        h2 {
          font-family: $font-family-monospace;
          font-weight: 600;
          color: color(olive);
          font-size: 4rem;
        }

        h4 {
          font-size: 1.1rem;
          font-weight: 800;
          color: color(green);
        }

        p {
          strong {
            display: block;
            width: 100%;
          }

          margin-top: 30px;
          color: color(olive);
          display: block;
          position: relative;
        }

        a.btn-know-more {
          &:hover {
            background: darken(color(green), 10);
          }

          background: color(green);
          color: white;
          border: none;
          padding: 12px 25px;
          font-weight: 600;
          border-radius: 24px;
          margin-top: 30px;
          text-decoration: none;
          display: inline-block;
        }
      }

      @media screen and (max-width: 1200px) {
        width: 45%;
      }

      @media screen and (max-width: 1024px) {
        width: 50%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      padding: 90px 40px 0px;
      width: 35%;
      margin-top: -70px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
  }

  img.astronauta {
    @media screen and (max-width: 1024px) {
      max-width: 45%;
    }

    position: absolute;
    right: 0px;
    bottom: 0px;
    max-width: 30%;
  }

  background: white url("../img/call-action-bg.png") center center no-repeat;
  background-size: cover;
  z-index: 30;
  position: relative;
}
