.content {
  .container {
    .products {
      .titles-container {
        > div {
          strong {
            color: black;
            font-size: 1.3rem;
          }

          h2 {
            @media screen and (max-width: 1200px) {
              font-size: 4rem;
            }

            @media screen and (max-width: 767px) {
              font-size: 2rem;
            }

            font-size: 5rem;
            margin-top: 10px;
            font-family: $font-family-monospace;
            font-weight: 700;
            color: color(green);
            width: auto;
          }

          &:last-child {
            h2 {
              small {
                font-size: 2rem;
                color: balck;
                line-height: 1;
                display: block;
                width: 100%;
                color: black;
                text-align: left;
              }

              color: color(yellow);
              text-align: right;
              line-height: 1;
              display: inline-block;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          width: 50%;
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        display: flex;
        flex-direction: row;
        width: 100%;
      }

      .products-container {
        > div {
          &:first-child {
            @media screen and (max-width: 767px) {
              width: 100%;
            }

            width: 65%;
          }

          .list-categories {
            li {
              &:before {
                @media screen and (max-width: 1200px) {
                  width: 12px;
                  height: 12px;
                  margin-top: -5px;
                }

                content: "";
                display: block;
                width: 12px;
                height: 12px;
                background-color: color(green);
                border-radius: 50%;
                position: absolute;
                left: 0px;
                top: 50%;
                margin-top: -7px;
              }

              @media screen and (max-width: 1200px) {
                font-size: 0.9rem;
              }

              @media screen and (max-width: 767px) {
                font-size: .6rem;
              }

              position: relative;
              display: inline-block;
              padding: 10px 20px 10px 20px;
              font-size: 1.1rem;
              font-weight: 500;
              color: color(olive);
            }

            list-style: none;
            padding-left: 0px;
            border-bottom: 2px solid color(light-grey);
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          width: 35%;
        }

        .product-images {
          .container-img {
            img {
              width: 100%;
              height: auto;
            }

            @media screen and (max-width: 767px) {
              margin-bottom: 15px;
            }

            border-radius: 15px;
            overflow: hidden;
            background: color(light-grey);
          }

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 4%;
        }

        .cards-products {
          .card {
            .card-image {
              img {
                width: 100%;
                height: auto;
              }

              margin-right: 20px;
              width: 15%;
            }

            .card-content {
              .title {
                @media screen and (max-width: 1200px) {
                  font-size: 1.1rem;
                }

                margin: 0px 0px 8px 0px;
                color: color(green);
                font-family: $font-family-monospace;
                font-weight: 600;
                font-size: 1.5rem;
              }

              .content {
                @media screen and (max-width: 1200px) {
                  font-size: 0.9rem;
                }

                padding: 0;
                color: color(olive);
                font-size: 1.2rem;
                margin-bottom: 0;
              }

              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 80%;
            }

            display: flex;
            flex-direction: row;
            margin: 25px 0;
          }
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        display: flex;
        flex-direction: row;
      }
    }

    .testimonials {
      .container {
        h2 {
          @media screen and (max-width: 1200px) {
            font-size: 4rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 2.5rem;
          }

          font-size: 6rem;
          color: color(green);
          text-align: center;
          font-family: $font-family-monospace;
          font-weight: 600;
        }

        h4 {
          @media screen and (max-width: 1200px) {
            font-size: 1.5rem;
          }

          @media screen and (max-width: 767px) {
            font-size: 1.2rem;
          }

          text-align: center;
          color: color(olive);
          font-weight: 700;
          font-size: 2.1rem;
        }

        .carousel-container {
          .item {
            .card {
              .info-client {
                p {
                  strong,
                  span {
                    display: block;
                    color: color(yellow);
                  }

                  strong {
                    @media screen and (max-width: 1200px) {
                      font-size: 1.2rem;
                    }

                    font-size: 1.5rem;
                  }

                  span {
                    @media screen and (max-width: 1200px) {
                      font-size: 1rem;
                      margin-top: 10px;
                    }

                    font-size: 1.2rem;
                    margin-top: 15px;
                  }

                  display: inline-block;
                  margin-bottom: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }

                img {
                  @media screen and (max-width: 1200px) {
                    max-width: 90px;
                  }

                  max-width: 110px;
                  height: auto;
                  display: inline-block;
                  margin-right: 20px;
                }

                display: flex;
                flex-direction: row;
                justify-content: middle;
              }

              .content-testimonial {
                .content {
                  @media screen and (max-width: 1200px) {
                    font-size: 0.9rem;
                  }

                  color: black;
                  padding: 0;
                }

                .stars {
                  li {
                    .fa {
                      color: color(yellow);
                    }

                    display: inline-block;
                  }

                  list-style: none;
                  padding: 0;
                  margin: 0;
                }

                &:before {
                  content: "";
                  display: block;
                  width: 0px;
                  height: 0px;
                  border: 20px solid color(yellow);
                  border-right-color: transparent;
                  border-top-color: transparent;
                  position: absolute;
                  top: -45px;
                  left: 100px;
                }

                position: relative;
                margin-top: 40px;
                border: 10px solid color(yellow);
                padding: 30px;
              }
            }
          }

          .owl-dots {
            .owl-dot {
              span {
                display: block;
                width: 25px;
                height: 7px;
                background: rgba(color(olive), 0.5);
                margin: 0px 5px;
              }

              &.active {
                span {
                  background: color(green);
                }
              }

              outline: none;
              display: inline-block;
            }

            margin: 30px 0;
            text-align: center;
          }

          margin-top: 100px;
        }
      }

      margin-top: 50px;
    }
  }

  padding: 50px 0px;
  background: white;
}
