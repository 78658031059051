header {
  .header-top {
    .container {
      ul {
        li {
          a {
            i {
              @media screen and (max-width: 1200px) {
                width: 25px;
                height: 25px;
              }

              display: inline-flex;
              vertical-align: initial;
              background-color: color(yellow);
              border-radius: 50%;
              width: 30px;
              height: 30px;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
            }

            @media screen and (max-width: 1200px) {
              font-size: 0.7em;
            }

            font-size: $small-font-size;
            font-weight: $font-weight-bold;
            display: block;
            padding: 15px 15px 15px 0;
            color: white;
            text-decoration: none;
          }

          &:last-child {
            a {
              i {
                background-color: color(green);
              }

              padding-right: 0;
            }

            margin-left: auto;
          }
        }

        width: 100%;
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    background-color: color(olive);
  }

  .header-bottom {
    .container {
      .main-navbar {
        ul {
          li {
            a {
              @media screen and (max-width: 1200px) {
                font-size: 1rem;
              }

              @media screen and (max-width: 1024px) {
                font-size: 0.9rem;
              }

              padding: 15px;
              color: color(olive);
              font-weight: $font-weight-bold;
              font-size: 1.2rem;
            }

            &:last-child {
              a {
                padding-right: 0;
              }
            }
          }

          @media screen and (max-width: 767px) {
            &.open {
              border-radius: 0;
              transform: scale(1);
            }

            overflow: hidden;
            width: 100%;
            height: auto;
            border-radius: 50%;
            background-color: white;
            transition: all 0.6s ease-in-out;
            transform-origin: right top;
            flex-direction: column;
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 100px;
            z-index: -1;
            transform: scale(0);
            transform-origin: top right;
            padding-bottom: 15px;
          }

          margin-left: auto;
          display: flex;
          flex-direction: row;
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;
        }

        #btn-toggle-menu {
          display: none;
          border: 1px solid white;
          background-color: color(olive);
          color: white;
          cursor: pointer;
          outline: none;
          border-radius: 0;
          padding: 0.35rem 0.7rem;
          z-index: 333;
          margin-left: auto;
        }

        @media screen and (max-width: 767px) {
          #btn-toggle-menu {
            display: block;
          }

          padding: 0px;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 0px;
      }

      @media screen and (max-width: 767px) {
        padding: 15px;
        margin-bottom: 200px;
      }
    }

    @media screen and (max-width: 767px) {
      position: fixed;
      z-index: 999;
      width: 100%;
      height: 89px;
    }

    background-color: white;
  }

  .banner {
    .container {
      .text-header {
        h1 {
          @media screen and (max-width: 1024px) {
            font-size: 3rem;
          }

          font-family: $font-family-monospace;
          color: color(yellow);
          font-weight: bold;
          font-size: 3.8rem;
          text-align: right;
        }

        p {
          @media screen and (max-width: 1024px) {
            font-size: 1rem;
          }

          color: white;
          text-align: right;
          font-size: 1.2rem;
        }

        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }

        max-width: 50%;
        margin-top: 7%;
      }

      height: 100%;
      min-height: 70vh;
      display: flex;
      padding-bottom: 80px;
      padding-top: 100px;
      align-items: center;
      justify-content: flex-end;
    }

    width: 100%;
    position: relative;
    background: white url("../img/header-bg.png") center center no-repeat;
    background-size: cover;
  }
}
