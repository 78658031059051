footer {
  .container {

    h3 {
      @media screen and (max-width: 1200px) {
        font-size: 2rem;
      }

      font-family: $font-family-monospace;
      font-weight: bold;
      font-size: 3rem;
      color: white;
    }

    h5 {
      @media screen and (max-width: 1200px) {
        font-size: 1.3rem;
      }

      font-size: 1.6rem;
      color: white;
      font-weight: 600;
    }

    .btn-taste {
      &:after {
        content: "";
        clear: both;
        position: relative;
      }

      margin: 50px 0;
      display: inline-block;
      padding: 15px 30px;
      color: white;
      font-weight: 600;
      border-radius: 30px;
      background: color(green);
      text-decoration: none;
    }

    img.cards {
      display: block;
      max-width: 100%;
      margin: 0 auto 30px;
    }

    p {
      @media screen and (max-width: 1200px) {
        font-size: 1rem;
      }

      color: white;
      font-size: 1.2rem;
    }

    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
  }


  width: 100%;
  background: white url('../img/footer-bg.png') center center no-repeat;
  background-size: cover;
}