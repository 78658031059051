// Global parts
@import "parts/header";

// Global styles
.container {
  @media screen and (min-width: 1360px) {
    max-width: $container-max-width-lg;
  }

  @media screen and (min-width: 1500px) {
    max-width: $container-max-width-xl;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  position: relative;
  margin: 0 auto;
  width: $container-width;
  max-width: $container-max-width;
}

body {
  #inicio,
  #obsequio,
  #testimonios,
  #productos {
    scroll-behavior: smooth;
  }


  .custom-checkbox {
    input[type="checkbox"] {
      &:checked {
        +span {
          &:after {
            content: " ";
            width: 7px;
            height: 7px;
            display: block;
            top: 3px;
            left: 3px;
            position: absolute;
            background-color: color(yellow);
          }

          border-color: color(yellow);
        }
      }

      + span {
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 5px;
        border: 1px solid white;
      }

      display: none;
    }

    padding-left: 25px;
    position: relative;
  }
  // background-color: black;
  font-family: $font-family-base;
}


@import "parts/footer";