/**
Color variable definitions
*/
$colors: (
  olive: #585944,
  olive-dark: #454637,
  yellow: #f1bb1a,
  green: #93c854,
  links: blue,
  dark: #333 ,
  light-grey: #f8f8f8
);

// function for get color quickly
@function color($color) {
  @return map-get($colors, $color);
}

$container-width: 100% !default;
$container-max-width: 970px !default;
$container-max-width-lg: 1200px !default;
$container-max-width-xl: 1360px !default;

/**
  Common variable definitions
*/
$enable-rounded: false !default;
$enable-pointer-cursor-for-buttons: true !default;

$body-text-align: null !default;
$hr-margin-y: 1rem !default;
$hr-color: inherit !default;
$hr-opacity: 0.25 !default;
$hr-height: 1px !default;

$headings-margin-bottom: 1rem / 2 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$paragraph-margin-bottom: 1rem !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$label-margin-bottom: 0.5rem !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$mark-padding: 0.2em !default;
$mark-bg: white !default;

/**
  Font variable definitions
*/
$font-family-sans-serif: 'Poppins', sans-serif !default;
$font-family-monospace: 'Aleo', serif, monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-root: null !default;
$font-size-base: 1rem !default;

$small-font-size: 0.875em !default;
$sub-sup-font-size: 0.75em !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$nested-kbd-font-weight: $font-weight-bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$dt-font-weight: $font-weight-bold !default;

/**
  Tables
*/
$table-cell-padding: 0.5rem !default;
$table-cell-padding-sm: 0.25rem !default;

$table-caption-color: color(dark) !default;

/**
  Links
*/
$link-color: color(links) !default;
$link-decoration: none !default;
$link-hover-color: darken(color(links), 15%) !default;
$link-hover-decoration: underline !default;

/**
  Code
*/
$code-font-size: $small-font-size !default;
$code-color: color(yellow) !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: white !default;
$kbd-bg: color(dark) !default;

$pre-color: null !default;
